<template>
    <headers :block="scrolledDistance>100?true:false"></headers>
    <div class="top-gap"></div>
    <!-- 赚取 -->
    <div class="launch">
        <div>
            <div class="title">{{ $t('home.launchTitle') }} <span>{{ $t('home.launchTitle1') }}</span> {{ $t('home.launchTitle2') }}</div>
            <div class="desc">{{ $t('home.launchDesc') }}</div>
            <div class="buts">
                <button @click="goPage('lp')">
                    {{ $t('home.but1') }}
                    <!-- <img src="../../static/images/arrow.png" > -->
                </button>
                <button @click="goPage('solfi')">
                    {{ $t('header.solfi') }}
                    <!-- <img src="../../static/images/arrow.png" > -->
                </button>
                <!-- <button @click="goPage('pay')">
                    SOLFI Pay
                </button> -->
                <button @click="goPage('ido')">
                    IDO
                </button>
            </div>
            <!-- <img class="bg" src="../../static/images/home-launch-bg.png" > -->
        </div>
    </div>
    <!-- 多样化 -->
    <div class="diversified-lay">
        <div class="diversified" v-for="(item,index) in 3" :key="index">
            <div class="left">
                <div class="title">{{ $t('home.diversifiedTitle' + index) }}<span>{{ $t('home.diversifiedTitle1' + index) }}</span></div>
                <div>{{ $t('home.diversifiedDesc' + index) }}</div>
            </div>
            <img :src="require('../../static/images/diversified' + index + '.png')" />
        </div>
    </div>
    <!-- 主要利益 -->
    <div class="benefit">
        <b class="title">{{ $t('home.benefit') }}</b>
        <div>
            <div v-for="(item,index) in 3" :key="index">
                <img :src="require('../../static/images/benefit' + index + '.png')" />
                <b>{{ $t('home.benefitTitle' + index) }}</b>
                <span>{{ $t('home.benefitText' + index) }}</span>
            </div>
        </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="friend">
        <b>{{ $t('home.friend') }}</b>
        <p>{{ $t('home.friendDesc') }}</p>
        <div>
            <img src="../../static/images/7.png" />
        </div>
    </div>
    <div></div>

    <bases></bases>
</template>
<script setup>
    import headers from '@/components/header'
    import bases from '@/components/base'
    import {computed, reactive, watch, onMounted} from 'vue';
    import Util from "@/utils/common-util";

    import {call, getContractAddress, send} from "@/contract/web3-util";
    import Web3 from "web3";
    import {memberStore} from "@/store/member"
    import {walletStore} from "@/store/wallet"
    import {webStore} from "@/store/web"
    import i18n from "@/i18n";
    import {ElMessage} from "element-plus";
    import {useRouter} from "vue-router";

    const mStore = memberStore()
    const wStore = walletStore()
    const wbStore = webStore()

    const router = new useRouter()

    onMounted(() => {
    })

    const tokenList = reactive([
        {name: 'price', num: '0', unit: 'USDT'},
        {name: 'market', num: '0', unit: 'USDT'},
        {name: 'tokenAll', num: '0', unit: 'ATI'},
    ])

    function toURL(url) {
      if (url) {
        window.open(url, '_blank')
      } else {
        ElMessage({
          message: i18n.global.t('alert.soon'),
          type: 'warning'
        })
      }
    }

    function goPage(url){
      router.push({
        path: url
      })
    }

    function toWhitePaper() {
      let language = localStorage.getItem('locale') || 'cn' ;
      let url = ''
      if (language === 'cn') {
        url = ''
      }
      toURL(url)
    }

    const curAccount = computed(()=>{
      return wStore.curAccount
    })
    watch(curAccount, (newVal, oldVal) =>{
      if (newVal) {
        if (oldVal) {
          alert(i18n.global.t('alert.reLogin'))
        }

        wbStore.accountLogin().then((result) => {
          wbStore.setLoginState(true)
          mStore.queryMemberInfo()

        }).catch((reason) => {
          wbStore.setLoginState(false)
          console.error('login failed')
        })
      }
    })
</script>
<style scoped src="./css/index.css"></style>
